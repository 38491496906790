import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

const fetchCleanings = formData => axios.post(
    `${API_URL}/admin/cleanings/overview`, formData, getApiHeaders()
);

const processCleaning = formData => axios.post(
    `${API_URL}/admin/cleanings/process`, formData, getApiHeaders()
);

const getFormFieldsCleaning = formData => axios.get(
    `${API_URL}/admin/cleanings/getformdata/`+ formData, getApiHeaders()
);

const processCreateCleaning = formData =>  axios.post(
    `${API_URL}/admin/cleanings/create`, formData, getApiHeaders()
)

export {
    fetchCleanings,
    processCleaning,
    getFormFieldsCleaning,
    processCreateCleaning
}


