import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFormFieldsCleaning, processCleaning } from "../helpers/cleanings";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { returnValues, removeValue, validateData } from "../../components/Form/Formfunctions";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { ValidateResponse } from "../helpers/global";


export function AppCleaningsProcess({
    setGlobalMessage,
    cleaningId,
    returnPath
}) {

    const navigate = useNavigate();
    const [formFields, setFormFields] = useState();
    const [defaultFormFields, setDefaultFormFields] = useState();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [reload, setReload] = useState(false);

    useEffect(() => {
        getFormFields('INITIAL');
    }, []);


    const getFormFields = (type) => {

        const formData = cleaningId + '/' + type;
        getFormFieldsCleaning(formData).then((res) => {
            const response = ValidateResponse(res.data, 'cleaning');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {

                let formValues = values;
                let customFields = response.cleaning;
                setDefaultFormFields(Object.assign({}, customFields));
                delete (customFields.warehouseId);
                setFormFields(customFields);

                Object.keys(formValues).forEach(field => {
                    if (customFields[field] === undefined) {
                        delete formValues[field];
                    }
                }
                )
                setValues(formValues);
            }

        }).catch((error) => {
            const response = ValidateResponse(error, 'cleaning');
            setGlobalMessage(response.message, response.status);
        })
    }

    const submitForm = () => {

        console.log(values);
        console.log(formFields);

        let validation = validateData(formFields, values, true);

        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        Object.keys(formFields).forEach(field => {
            if (values[field] === undefined) {
                values[field] = '';
            }
        })

        let formData = {
            'cleaningId': cleaningId,
            'data': values,
        }

        processCleaning(formData).then((res) => {
            const response = ValidateResponse(res.data);
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                navigate(returnPath + '/overview', {
                    replace: true,
                    state: { 'message': response.message, 'type': response.status, 'currentPage': 1 }
                });
            }

        }).catch((error) => {
            const response = ValidateResponse(error);
            setGlobalMessage(response.message, response.status);
        })
    }

    const setInputValues = (key, value, identifier = null) => {
        // Load form fields based on chosen values
        if (key === 'status') {
            if (value === 'CLEANING') {
                getFormFields('CLEANING');
            }
            else if (value === 'REPAIR') {
                getFormFields('REPAIR');
            }

        }

        if (key === 'changeWarehouse') {
            let fieldsCleaning = formFields;
            if (value === 'NONE') {
                delete fieldsCleaning.warehouseId;
                delete values.warehouseId;
            } else {
                fieldsCleaning.warehouseId = defaultFormFields.warehouseId;
            }
            setFormFields(fieldsCleaning);
            setReload(reload === false);
        }

        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }
        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const contentValidate = () => {

        return (
            <>
                <FormFields
                    formFields={formFields}
                    setValues={setInputValues}
                    setErrors={setInputErrors}
                    formValues={values}
                    formErrors={errors}
                    wrapper="wrapper"
                />

                <div className="spacer" />

                <FormInputSubmit handleSubmit={submitForm} submitLabel="Verwerken" />
            </>
        )
    }

    if (formFields !== undefined) {
        return (
            <div>
                <ContentWrapper type='outline-box' content={contentValidate()} />
            </div>
        );
    }
    else {
        return <></>
    }
}