import React, { useEffect, useState } from "react";
import { fetchWarehouses, ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchCleanings } from "../helpers/cleanings";
import { useLocation } from "react-router-dom";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";
import { List } from "../../components/List";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppCleaningsOverview({ current, identifier, setFilterFields, filterValues, setGlobalMessage }) {

    let pageSize = 10; // default values ergens globaal opslaan?
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [filterString, setFilterString] = useState("{}");
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters' + identifier)));
    const [data, setData] = useState();
    const [warehouses, setWarehouses] = useState({});


    useEffect(() => {
        if (location.state !== null && location.state.currentPage !== undefined) {
            setCurrentPage(1, current, setPageNr);
        }
    }, [location.state]);

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    let storedPageNr = getCurrentPage(current);
    if (pageNr !== storedPageNr) {
        setPageNr(storedPageNr);
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    // Default list columns
    let listColumns = [
        { key: "name", type: "title", hideView: "large" },
        { key: "createDate", label: "Aanmaakdatum", type: "date" },
        { key: "name", label: "Naam", type: "text" },
        { key: "articleCode", label: "Artikelcode", type: "text" },
        { key: "serialNumber", label: "Product serienummer", type: "text" },
        { key: "productStatus", label: "Productstatus", type: "text" },
        { key: "reference", label: "Referentie", type: "text" },
    ];

    // Set specific columns for archive or active
    if (current === 'archive') {
        delete (listColumns[3]);
        listColumns.push({ key: "cleanDate", label: "Datum reiniging", type: "date" })
    }

    listColumns.push({ key: "warehouse", label: "Magazijn", type: "text" },)

    // Add the processing link for active cleanings with online form
    if (current === 'overview') {
        let link = "/cleanings/process/:cleaningId";

        listColumns[0] = { key: "name", type: "link", path: link, id: "cleaningId", hideView: "large" };
        listColumns.push({ key: "cleaningId", type: "link", path: link, id: "cleaningId", hideView: "small" })
    }

    const defineFilters = () => {
        let filterFields = { 
            'serialNumber': { type: 'text', label: "Product serienummer" } ,
            'warehouseId': { type: 'select', label: "Magazijn", options: warehouses} 
        }

        if (current === 'archive') {
            filterFields = {
                'serialNumber': { type: 'text', 'label': "Product serienummer" },
                'fromDate': { type: 'date', 'label': "Datum vanaf" },
                'toDate': { type: 'date', 'label': "Datum tot" },
            }
        }
        setFilterFields(filterFields);
    }

    useEffect(() => {

        const callData = {
            filters: {
                context: current,
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }

        fetchCleanings(callData).then((res) => {
            const response = ValidateResponse(res.data, 'cleanings');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                setData(response.cleanings);
                setTotalCount(response.totalCount)
            }
            setIsLoaded(true);
            defineFilters(); // activate filters if data is loaded
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setGlobalMessage(response.message, response.status);
        })
    }, [filters, setData, pageNr, setTotalCount, setIsLoaded, setData, isLoaded, current, pageSize])

    useEffect(() => {
        fetchWarehouses().then((res) => {
            const response = ValidateResponse(res.data, 'warehouses');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                setWarehouses(response.warehouses);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message, response.status);
        })
    }, [setWarehouses])

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (isLoaded === true && (data === undefined || data === null || Object.keys(data).length === 0)) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={data}
            />
            <ListPagination
                className="pagination-bar"
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
        </>)
}